// Styling for the masthead
header.masthead {
  text-align: center;
  color: white;
  background-image: url("#{$header-image-mobile}");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  img{
    max-width: 80%;
    height: auto;
    }

  .intro-text {
    padding-top: 100px;
    padding-bottom: 100px;

    .intro-lead-in {
      font-size: 22px;
      font-style: italic;
      line-height: 22px;
      margin-bottom: 25px;
      @include serif-font;
    }
    .contact-link {
      color: white;
      font-size: 22px;
      margin-bottom: 25px;
      @include heading-font;
    
      a {
        text-decoration: none;
    
        .icon-and-text {
          display: flex;
          align-items: center; 
          margin-right: 16px;
          margin-left: 16px;
          span {
            color: white;
            margin-left: 10px; 
          }
        }
      }
    }   
    
    .intro-heading {
      font-size: 50px;
      font-weight: 700;
      line-height: 50px;
      margin-bottom: 25px;
      @include heading-font;
    }
  }
}

@media (min-width: 768px) {
  header.masthead {
    background-image: url("#{$header-image}");
    img {
        max-width: 510px;
      }

    .intro-text {
      padding-top: 200px;
      padding-bottom: 200px;

      .intro-lead-in {
        font-size: 40px;
        font-style: italic;
        line-height: 40px;
        margin-bottom: 25px;
        @include serif-font;
      }

      .intro-heading {
        font-size: 75px;
        font-weight: 700;
        line-height: 75px;
        margin-bottom: 50px;
        @include heading-font;
      }
    }
  }
}